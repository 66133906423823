<template>

<Box> <!-- main -->

  <SpacerRow />
  
  <Row :showBorder="true">
    <div class="rc-box-col-12">
      <Box class="rc-text-medium rc-text-value">
        
        <Row>
          <div class="rc-box-col-15 rc-text-label">
            {{ C.get(K.FILTERS.TITLE) }}
          </div>
        </Row>
        <Row>
          <div class="rc-box-col-1">
            <Button v-on:click="allToggle()" 
              :disabled="allToggled()">
            All
            </Button>
          </div>

          <div class="rc-box-col-1">
            <Button v-on:click="detailsToggle()"
              :disabled="detailsToggled()">
            Details
            </Button>
          </div>
          <div class="rc-box-col-1">
            <Button v-on:click="invoicesToggle()"
              :disabled="invoicesToggled()">
            Invoices
            </Button>
          </div>
          <div class="rc-box-col-11" />
        </Row>
      </Box>
    </div>
    
    <div class="rc-box-col-3">
      <Box class="rc-text-medium">
        <Row>
          <Column class="rc-text-label">
            {{ C.get(K.ACTIONS.TITLE) }}
          </Column>
        </Row>

        <Row>
          <div class="rc-box-col-8">
          </div>
          <div class="rc-box-col-7">
            <Button v-on:click="edit">
            Edit
            </Button>
          </div>
        </Row>
        
      </Box>
    </div>
  </Row>

  <TitleRow align="left"  v-if="showingDetails">  
    Billing Details
  </TitleRow>
  
  <Row :showBorder="true" v-if="showingDetails"> <!-- row1 -->
    <Column> <!-- middle -->
      <Box>

        <TitleRow align="left" :subtitle="true"> Billing Company </TitleRow>

        <LabelValueRow>
          <template #label>Company Name</template>
          <template #value>{{ settings.companyName() }}</template>
        </LabelValueRow>
      
        <SpacerRow />
        
        <TitleRow align="left" :subtitle="true"> Billing Contact </TitleRow>
        
        <LabelValueRow>
          <template #label>Contact Name</template>
          <template #value>{{ settings.contactName() }}</template>
        </LabelValueRow>
        
        <LabelValueRow>
          <template #label>Contact Phone</template>
          <template #value>{{ settings.contactPhone() }}</template>
        </LabelValueRow>
        
        <LabelValueRow>
          <template #label>Contact Email</template>
          <template #value>{{ settings.contactEmail() }}</template>
        </LabelValueRow>
        
        <SpacerRow />
        
        <TitleRow align="left" :subtitle="true"> Billing Address </TitleRow>

        <AddressRow :addressData="address.data" />
        
        <SpacerRow />
        
        <TitleRow align="left" :subtitle="true">Purchase Order # and Payment Method</TitleRow>
        
        <LabelValueRow>
          <template #label>Purchase Order</template>
          <template #value>{{ settings.purchaseOrder() }}</template>
        </LabelValueRow>
      
        <LabelValueRow>
          <template #label>Payment Method</template>
          <template #value>{{ settings.paymentMethod() }}</template>
        </LabelValueRow>
        
        <SpacerRow />
        
        <TitleRow :subtitle="true" align="left"> Invoices Notification Email and Credit </TitleRow>
        <ValueRow align="left">
          A copy of the invoice will be sent to these addresses (separate multiple addresses with a comma)
        </ValueRow>
        <ValueRow align="left">
          Addresses: {{ settings.emailTo() }}
        </ValueRow>
        
        <LabelValueRow>
          <template #label>Remaining Credit</template>
          <template #value>{{ company.creditDisplay() }}</template>
        </LabelValueRow>
        
      </Box>
    </Column> <!-- middle -->
  </Row> <!-- row1 -->

  <SpacerRow v-if="showingDetails" />
  
  <TitleRow  align="left"
    v-if="showingInvoices">
    Invoices
  </TitleRow>
    
  <Row v-if="showingInvoices" :showBorder="true">
    <Column> 
      <InvoicesTable :isAdmin="isAdmin" />
    </Column>
  </Row>

</Box><!-- main -->

</template>

<script>

import { mapGetters } from 'vuex'

import Catalog    from "@/domain/session/Catalog.js";
import CE from  "@/domain/session/CanadianEnglish.js";
import ConstUtils from '@/utils/ConstUtils.js';
import DateUtils  from '@/utils/DateUtils.js';

import AddressRow   from "@/components/row/AddressRow";

import InvoicesTable from '@/portals/customer/operator/views/invoice/list/Invoices.vue';

import Company from "@/domain/model/company/Company.js";
import InvoiceSettings from "@/domain/model/invoice/InvoiceSettings.js";
//import ContentUtils from '@/utils/ContentUtils.js';

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

//import Title from "@/portals/shared/library/title/Title.vue";
import TitleRow from "@/portals/shared/library/title/TitleRow.vue";

//import Spacer from "@/portals/shared/library/spacer/Spacer.vue";
import SpacerRow from "@/portals/shared/library/spacer/SpacerRow.vue";

import Button from "@/portals/shared/library/button/Button.vue";
//import Value from "@/portals/shared/library/value/Value.vue";
import ValueRow from "@/portals/shared/library/value/ValueRow.vue";

//import LabelValue from "@/portals/shared/library/labelvalue/LabelValue.vue";
import LabelValueRow from "@/portals/shared/library/labelvalue/LabelValueRow.vue";

export default {
  name: "operator-billing-details",
  components: {
    AddressRow,
    Button,
    SpacerRow,
    InvoicesTable,
    Box, Row, Column,
    TitleRow,
    ValueRow, LabelValueRow,
  },
  props: {
    isAdmin: { type: Boolean, default: false },
  },
  data() {
    return {
      C: new Catalog(),
      CK: Catalog,
      K: CE.KEYS,
      showingDetails: true,
      showingInvoices: true,
    };
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'domain',
                ]),
    settings: function() {
      if (!this.domain || !this.domain.session()) {
        return new InvoiceSettings();
      }
      var invoiceSettings = this.domain.session().company().invoiceSettings();
      return invoiceSettings;
    },
    address: function() {
      return this.settings.address().find();
    },
    company: function() {
      if (this.domain && this.domain.session()) {
        return this.domain.session().company();
      }
      return new Company();
    },
  },
  watch: {
    
  },
  mounted: function () {
    if (!this.auth_connected) {
      this.$router.replace({ name: this.$route.query.redirectTo || ConstUtils.ROUTES.SIGNIN })
    } else {
      this.allToggle();
    }
  },
  methods: {
    displayMessage: function() {
      if (this.domain.session().company().createdDate() < DateUtils.august12021()) {
        return "Invoices appear the first week of the month, invoices prior to Aug 2021 will not appear.";
      }
      return "Invoices appear the first week of the month";
    },
    edit: function() {
      var params = {
        id: this.settings.id()
      };
      var to = {
        name: ConstUtils.ROUTES.OPERATOR.BILLING_EDIT,
        params: params,
      };
      this.$router.push(to);
    },
    detailsToggle: function() {
      this.showingDetails = true;
      this.showingInvoices = false;
    },
    invoicesToggle: function() {
      this.showingDetails = false;
      this.showingInvoices = true;
    },
    allToggle: function() {
      this.showingDetails = true;
      this.showingInvoices = true;
    },
    allToggled: function() {
      return this.showingDetails && this.showingInvoices;
    },
    invoicesToggled: function() {
      return this.showingInvoices && !this.showingDetails;
    },
    detailsToggled: function() {
      return this.showingDetails && !this.showingInvoices;
    },
  }
}
</script>